<template>
  <LxpMobileHeader :title="svy.svyNm">
    <template v-slot:left>
      <div class="util util-back">
        <a href="javascript:" class="util-actions util-actions-back" @click="closeModal">
          <i class="icon-history-back"></i>
        </a>
      </div>
    </template>
  </LxpMobileHeader>
  <main class="kb-main" id="kb-myclass">
    <!-- main-content -->
    <div class="main-content min-component">
      <div v-if="cnVal.length > 0" class="top-notice">
        <p v-for="(elem, idx) in cnVal" :key="`cn-${idx}`" class="text">
          {{ elem }}
        </p>
      </div>
      <div class="content-section-group content-section-survey">
        <div>
          <header class="group-header">
            <h3 class="title">{{ svy.svyNm }}</h3>
            <p class="text font-body">{{ timestampToDateFormat(svy.svyBgngDt, 'yyyy.MM.dd.w') }} - {{ timestampToDateFormat(svy.svyEndDt, 'yyyy.MM.dd.w') }}  |  {{ timestampToDateFormat(svy.svyEndDt, 'yyyy.MM.dd hh:mm') }}까지</p>
          </header>

          <!-- content-section -->
          <article v-if="isCollective" class="content-section section-margin">
            <header class="section-header header-divider">
              <h3 class="title font-body16B">강의정보</h3>
            </header>
            <!-- text-item-container -->
            <div class="text-item-container">
              <!-- text-item -->
              <div class="text-item">
                <div class="item-row row-contents">
                  <div class="item-column">
                    <h4 class="item-subtitle">강의명</h4>
                    <div class="item-meta fsize-m">
                      <span class="text">{{ item.objNm }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <!-- text-item -->
              <div v-if="item.chapNm" class="text-item">
                <div class="item-row row-contents">
                  <div class="item-column">
                    <h4 class="item-subtitle">챕터명</h4>
                    <div class="item-meta fsize-m">
                      <span class="text">{{ item.chapNm }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <!-- text-item -->
              <div class="text-item">
                <div class="item-row row-contents">
                  <div class="item-column">
                    <h4 class="item-subtitle">강사</h4>
                    <div class="item-meta fsize-m">
                      <span class="text">{{ getTutors(item, '-') }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <!-- text-item -->
              <div class="text-item">
                <div class="item-row row-contents">
                  <div class="item-column">
                    <h4 class="item-subtitle">일정</h4>
                    <div class="item-meta fsize-m">
                      <span class="text">{{ timestampToDateFormat(item.objBgngDt, 'yyyy.MM.dd') }} - {{timestampToDateFormat(item.objEndDt, 'yyyy.MM.dd')}}</span>
                    </div>
                  </div>
                </div>
              </div>
              <!-- //text-item -->
            </div>
            <!-- //text-item-container -->
          </article>

          <template v-if="isReady">
            <template v-for="(part, index) in parts" :key="index">
              <div v-if="part.svyPartNm" class="survey-article survey-notice mt-4">
                <h3 class="title">{{ part.svyPartNm }}</h3>
<!--                <p class="text">신청하신 과정에 대한 이해도를 묻는 설문입니다. 알고 계신 범위에 대한 솔직한 답변 부탁드립니다.</p>-->
              </div>
              
              <template v-for="(question, idx) in part.questions" :key="`${index}-${idx}`">
                <article v-if="question.isShow" class="survey-article">
                <header class="survey-header">
                  <h4 class="title">{{ getQuestionNo(parts, index, idx) }}. {{ question.qstnNm }}</h4>
                </header>

                <template v-if="selectTypes.includes(question.qstnKindCdDcd)">
                  <div v-if="question.etcAnsYn === 'Y'" class="survey-checklist-th">
                    <span class="label"></span>
                    <button class="kb-btn-text" @click="toggleEtc(question)"><span class="text text-primary">{{ question.etc ? '취소' : '기타입력' }}</span></button>
                  </div>

                  <div class="survey-checklist">
                    <!-- kb-form-check-group -->
                    <div class="kb-form-check-group">
                      <!-- kb-form-check -->
                      <div v-for="(option, i) in question.options" class="kb-form-check" :key="`${index}-${idx}-${i}`">
                        <input
                            v-if="question.qstnKindCdDcd === '2020002'"
                            v-model="question.model"
                            type="checkbox"
                            class="kb-form-check-input"
                            :name="`chk-${index}-${idx}-${i}`"
                            :id="`chk-${index}-${idx}-${i}`"
                            :value="option.optNo"
                            :disabled="!!question.etc"
                        >
                        <input
                            v-else
                            v-model="question.model"
                            type="radio"
                            class="kb-form-check-input"
                            :name="`chk-${index}-${idx}`"
                            :id="`chk-${index}-${idx}-${i}`"
                            :value="option.optNo"
                            :disabled="!!question.etc"
                            @click="chkLink(part)"
                        >
                        <label :for="`chk-${index}-${idx}-${i}`" class="kb-form-check-label">{{ option.optCn }}</label>
                      </div>
                    </div>
                  </div>

                  <div v-if="question.etc" class="survey-textarea">
                    <textarea v-model.trim="question.etcAns" class="kb-form-control" placeholder="기타 입력"></textarea>
                  </div>
                </template>
                <div v-else class="survey-textarea">
                  <textarea v-model="question.model" class="kb-form-control" placeholder="내용을 입력해주시기 바랍니다."></textarea>
                </div>
                <div v-if="question.invalidated" class="survey-feedback">
                  <p class="text text-red"><i class="icon-feedback"></i> 필수 항목 입니다</p>
                </div>
              </article>
              </template>
            </template>

            <div class="page-buttons">
              <button class="kb-btn kb-btn-primary" @click="submitSurvey"><span class="text">제출</span></button>
            </div>
          </template>
        </div>
      </div>

    </div>
    <!-- //main-content -->
  </main>
</template>

<script>

import LxpMobileHeader from '@/views/layout/lxp/header/mobile/LxpMobileHeader';
import {learnSvySetup} from '@/assets/js/modules/learn/learn-svy-setup';

export default {
  name: 'MobileLearnSurvey',
  components: {LxpMobileHeader},
  props: {
    item: Object,
    svy: Object,
    hasCallback: Boolean
  },
  emits: ['renewEnd'],
  setup: learnSvySetup
};
</script>
