<template>
  <MobileLearnSurvey
      v-if="surveyModal"
      :item="params"
      :svy="survey"
      :has-callback="true"
      @renewEnd="getLearnObjectResult"
  />
</template>

<script>

import {computed, onMounted, reactive, ref} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import {ACT_GET_CRSE_DIST_APPLY} from '@/store/modules/course/course';
import {getFunc} from '@/assets/js/ui.init';
import {useStore} from 'vuex';
import {useAlert} from '@/assets/js/modules/common/alert';
import MobileLearnSurvey from '@/components/learn/mobile/window/MobileLearnSurvey';
import {getItem, getResult} from '@/assets/js/util';
import {ACT_GET_CNTST_SVY} from '@/store/modules/survey/survey';
import {applyCourse} from '@/assets/js/modules/course/course-common';

export default {
  name: 'ApplyCntst',
  components: {
      MobileLearnSurvey,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const {showLoading, hideLoading, showMessage} = useAlert();
    const distCrseSn = computed(() => route.params.distCrseSn);
    const surveyModal = ref(false);
    const survey = ref({});
    const params = reactive({
      distCrseSn: 0,
      crseMstSn: 0,
      classCateSn: 0,
      classCateNm: '',
      mediaCateSn: 0,
      mediaCateNm: '',
      orgnzCdDcd: '',
      orgnzNm: '',
      prvdrSn: 0,
      prvdrNm: '',
      introDivCdDcd: '',
      introDivNm: '',

      crseNm: '',

      crseSumup: '',
      crseDtl: '',
      exptAblty: '',
      crseLvl: '',

      classUseYn: '',
      classCnt: 0,
      bgngDt: 0,
      endDt: 0,

      thumb: '',
      etcThumb: '',
      prevUrl: '',
      crseMlg: 0,

      totLrnPerid: 0,
      totLrnHr: 0,

      fnshCondYn: '', // 수료조건 여부
      rfltRateUseYn: '', // 반영 비율 사용 여부
      fnshCrtrUseYn: '', // 수료 기준 사용 여부
      fnshCrtrScore: 0, // 수료 기준 점수
      eduCost: 0, // 교육 비용
      unfiRmbrAmt: 0, // 미수료 환급 금액

      totBgt: 0, // 총 예산
      instrCost: 0, // 강사료

      lrnTrgtGrpMstSn: 0, // 학습 그룹

      eduTrnctCdDcd: '', // 연수원 DCD
      eduTrnctNm: '', // 연수원
      loc: '', // 장소

      peoplCnt: 0, // 인원 수
      aplyBgngDt: 0, // 신청 시작 일시
      aplyEndDt: 0, // 신청 종료 일시

      rtrcnPsbltYn: '', // 신청 취소 강능 여부
      rtrcnEndDt: 0, // 취소 가능 종료일시

      cntstCrseYn: '', // 공모여부과정
      selecProcYn: '', // 공모여부과정
      mstThumb: '',
      stayAplyRcptYn: '',
      befCrseCd: '',
      svyRsltCnt: 0,
      splCrseTyCdDcd: '',

    });

    const initLearnMetaList = () => {
      getFunc(`course/${ACT_GET_CRSE_DIST_APPLY}`, distCrseSn.value, params, getCallback);
    };

    const getCallback = () => {
      params.crseSumup = params.crseSumup.replace(/(?:\r\n|\r|\n)/g, '<br />')
      params.crseDtl = params.crseDtl.replace(/(?:\r\n|\r|\n)/g, '<br />')
      openSurvey();
    };

    const openSurvey = () => {
      showLoading();
      store.dispatch(`survey/${ACT_GET_CNTST_SVY}`, params.distCrseSn).then(res => {
        // 설문 정보가 존재한다.
        survey.value = getItem(res);
        surveyModal.value = true;
      }).catch(e => {
        console.error(e);
        showMessage('오류가 발생했습니다. 다시 시도해주세요.');
        hideLoading();
      });
    };

    const applyTargetCourse = () => {
      applyCourse(
          params.distCrseSn,
          {},
          () => {
            hideLoading();

            if(params.splCrseTyCdDcd=== '2066030'){
              showMessage('수강신청이 완료되었습니다. ');
              router.push({name: 'TrainGlobal'});
            }else {
              showMessage('수강신청이 완료되었습니다.');
              router.push({name: 'ApplyStatus'});
            }
            // router.push({name: 'ApplyStatus'});
          },
          (res) => {
            hideLoading();
            showMessage(getResult(res).message);
          },
          () => {
            hideLoading();
            showMessage('오류가 발생했습니다. 잠시 후 다시 시도해주세요.');
          },
      );
    };

    const getLearnObjectResult = () => {
      applyTargetCourse();
    };

    onMounted(() => {
      initLearnMetaList();
    });

    return {
      params,
      survey,
      surveyModal,
      getLearnObjectResult,
    };

  },
};
</script>
